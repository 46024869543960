<template>
  <div class="course-detail flex">
    <div class="course-detail__left" data-aos="fade-up">
      <div class="banner-top">
        <div class="banner-top__bg">
          <img :src="data?.image" alt="" />
        </div>
        <div
          class="banner-top__content"
          data-aos="zoom-in"
          data-aos-duration="3000"
        >
          <div class="title" v-html="data[locale]?.name"></div>
          <div class="line"></div>
        </div>
        <div class="banner-top__cross">
          <router-link :to="'/price-list'" @click="setActive"
            >Bảng Phí</router-link
          >
        </div>
      </div>
      <category
        :categoryBlue="true"
        :afterLink="afterLink"
        :beforeLink="beforeLink"
      />
      <div class="content pc">
        <div class="title">
          <h5>THÔNG TIN HỐ GOLF</h5>
          <h1 v-html="data[locale]?.title"></h1>
        </div>
        <div class="des">
          <p v-html="data[locale]?.description">
          </p>
        </div>
        <div class="line"></div>
        <div class="info">
          <p v-html="data[locale]?.content">
          </p>
        </div>
      </div>
      <div
        class="related flex justify-between pc"
        data-aos="fade-up"
        v-if="infomation"
      >
        <div
          class="item"
          v-for="(item, index) in infomation"
          :key="index"
          @click="item.id === 1 ? activePopupSecore(item.general) : ''"
        >
          <a
            :href="
              item?.link ||
              (item.id === 2 ? item?.general : 'javascript:void(0)')
            "
            :target="item.id === 1 ? '' : '_blank'"
            rel="noopener noreferrer"
          >
            <div class="item__bg">
              <img :src="item.background" alt="" />
            </div>
            <div class="item__content">
              <div class="title">{{ item[locale].name }}</div>
              <div class="line"></div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="course-detail__right" data-aos="fade-up">
      <div class="list-course">
        <div
          class="item"
          v-for="(item, index) in listHole"
          :key="index"
          @click="activePopup(item.id)"
        >
          <div class="item__bg">
            <img :src="item.image" alt="" />
          </div>
          <div class="item__number">{{ item.number }}</div>
        </div>
      </div>
    </div>
    <div class="content-mb mb">
      <div class="content">
        <div class="title">
          <h5>THÔNG TIN HỐ GOLF</h5>
          <h1>{{ data[locale]?.title }}</h1>
        </div>
        <div class="des">
          <p>
            {{ data[locale]?.description }}
          </p>
        </div>
        <div class="line"></div>
        <div class="info">
          <p>
            {{ data[locale]?.content }}
          </p>
        </div>
      </div>
      <div class="related flex justify-between" v-if="infomation">
        <div
          class="item"
          v-for="(item, index) in infomation"
          :key="index"
          @click="item.id === 1 ? activePopupSecore(item.general) : ''"
        >
          <a
            :href="
              item?.link ||
              (item.id === 2 ? item?.general : 'javascript:void(0)')
            "
            :target="item.id === 1 ? '' : '_blank'"
            rel="noopener noreferrer"
          >
            <div class="item__bg">
              <img :src="item.background" alt="" />
            </div>
            <div class="item__content">
              <div class="title">{{ item[locale].name }}</div>
              <div class="line"></div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <popupCourse :data="hole" />
    <popupImage :img="img" :attr="'score'" />
  </div>
</template>

<script>
/* global $ */
import popupCourse from "@/components/popup/popupCourse.vue";
import popupImage from "@/components/popup/popupImage.vue";
import CourseService from "@/services/CourseService";
import category from "@/components/category/category.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    popupCourse,
    popupImage,
    category,
  },
  data() {
    return {
      data: "",
      listHole: "",
      hole: "",
      popupHole: "",
      infomation: "",
      img: "../img/common/score.jpg",
      beforeLink: {
        link: "/golf-course",
        name: "Sân",
        main: 0,
        sub: true,
      },
      afterLink: {
        link: "/golf-course/detail",
        name: "Amber Hills Golf & Resort",
        main: 0,
        sub: 0,
      },
    };
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.dataCourse();
    this.getListHole();
    this.getInfo();
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  methods: {
    setActive() {
      this.$store.commit("setMenuActive", 0);
      this.$store.commit("setSubActive", 1);
    },
    activePopup(id) {
      $("#popupcommoncourse").removeClass("hide");
      $("#popupcommoncourse").addClass("active");
      CourseService.detailHole(id)
        .then((resp) => {
          this.hole = resp.data.data;
        })
        .catch(() => {});
    },
    activePopupSecore(general) {
      this.img = general;
      $("#popupcommonscore").removeClass("hide");
      $("#popupcommonscore").addClass("active");
    },
    async dataCourse() {
      await CourseService.getCourse(1)
        .then((resp) => {
          this.data = resp.data.data;
          this.afterLink.name = this.data[this.locale]?.name;
        })
        .catch(() => {});
    },
    async getListHole() {
      await CourseService.getListHole(1)
        .then((resp) => {
          this.listHole = resp.data.data;
        })
        .catch(() => {});
    },
    async getInfo() {
      await CourseService.generalInformationCourse()
        .then((resp) => {
          this.infomation = resp.data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
