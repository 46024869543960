<template>
  <popupcommon :iconClose="iconClose" v-if="data" :attr="'course'">
    <div class="popup-main__course">
      <div class="popup__bg">
        <img class="bg-cover" :src="data.image" alt="" />
        <img class="bg-map" :src="data.detail" alt="" />
      </div>
      <div class="popup__detail">
        <div class="title">
          <h1>
            {{ data[locale]?.name }} <br />
            CHIẾN THUẬT
          </h1>
          <div class="icon">
            <img src="../../assets/img/villa/flag-course.svg" alt="" />
            <p>PAR {{data.par}}</p>
          </div>
        </div>
        <div class="number-pit">
          <div class="pit">
            <div class="pit__bg pit-yellow"></div>
            <p class="pit__number">{{ data.yellow }}</p>
          </div>
          <div class="pit">
            <div class="pit__bg pit-blue"></div>
            <p class="pit__number">{{ data.blue }}</p>
          </div>
          <div class="pit">
            <div class="pit__bg pit-white"></div>
            <p class="pit__number">{{ data.while }}</p>
          </div>
          <div class="pit">
            <div class="pit__bg pit-red"></div>
            <p class="pit__number">{{ data.red }}</p>
          </div>
        </div>
        <div class="pit-tee">
          <h5 class="pit-tee__title"> {{ data[locale]?.title }}</h5>
          <p class="pit-tee__content">
            {{ data[locale]?.description }}
          </p>
        </div>
      </div>
    </div>
  </popupcommon>
</template>
<script>
import popupcommon from "@/components/popup/popupcommon.vue";
import { mapGetters } from 'vuex';
export default {
  components: { popupcommon },
  props: {
    data: null,
  },
  data() {
    return {
      iconClose: true,
    };
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  methods: {},
};
</script>
