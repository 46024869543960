<template>
  <div :class="[categoryBlue ? 'category-blue' : '', 'category pc']">
    <img src="../../assets/img/logo/logo-category.svg" alt="" />
    <router-link class="before" :to="'/'"> Trang chủ </router-link>
    <span class="tag-mid">/</span>
    <router-link v-if="beforeLink" class="before" :to="beforeLink.link" @click="setMenu(beforeLink.main, beforeLink.sub)">
      {{ beforeLink.name }} <span class="tag-mid">/</span>
    </router-link>
    <router-link v-if="afterLink" class="after" :to="afterLink.link" @click="setMenu(beforeLink.main, beforeLink.sub)">
      {{ afterLink.name }}
    </router-link>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  components: {},
  props: {
    beforeLink: null,
    afterLink: null,
    categoryBlue: null,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
      ...mapActions(["setMenu"]),
  },
};
</script>
